import './App.css';

function App() {
  return (
    <div id='container_main'>
      <h1 id='content' className='typewriter color_white'>Gabriel Magni | Dev</h1>
      <div className='contact'>
        <span>
          <a target={'_blank'} href="https://github.com/GabrielPMagni/">
            <img src="/github.svg" alt="GitHub" />
          </a>
        </span>
        <span>
          <a target={'_blank'} href="https://www.linkedin.com/in/gabriel-peres-magni-246a49158/">
            <img src="/linkedin.svg" alt="LinkedIn" />
          </a>
        </span>
      </div>
    </div>
  );
}

export default App;
